// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";

const state = {
  invoices: [],
  invoicedata: {},
  singleInvoice: {},
  notes: {},
};

const getters = {};

const actions = {
  getAllInvoices(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/invoice/all", {
          params: {
            search: data.search,
            pageNo: data.pageNo,
            pageSize: data.pageSize,
            type: data.type,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_invoices", response.data.data);
            context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSingleInvoice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/invoice/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_single_invoice", response.data.data.invoice);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getSearchInvoices(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/invoice/all?search=${data}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("set_invoices", response.data.data);
          context.commit("set_count", response.data.count);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getInvoiceResources(context, matterId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/invoice/resources/${matterId}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getNotes(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/invoice-comment`, {
          params: {
            invoice_id: data.id,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("set_notes", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createNotes(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/invoice-comment`, payload, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createInvoice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/invoice/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createInvoiceExpense(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("/invoice/add-expense", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("add_expense", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createBulkInvoice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/invoice/bulk-upload/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  markedPaidInvoice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/invoice/mark_paid/${data.id}`, data.paidStatus, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteResource(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/invoice/resource`, {
          data: data.data, 
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteExpense(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/invoice/del-expense`, {
          data: data.data, 
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  markedInvoiceReady(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/invoice/mark_invoice_ready/${data.id}`,
          {}, // Empty body
          {
            headers: {
              ...authHeaders(localStorage.getItem("access_token")),
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  markedAsInvoice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/invoice/mark_is_invoiced/${data.id}`,
          {}, // Empty body
          {
            headers: {
              ...authHeaders(localStorage.getItem("access_token")),
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  cancelInvoice(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/invoice/mark_cancelled/${data.id}`, data.cancelledStatus, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  add_invoice_obj(state, data) {
    state.entries.push(data);
  },
  set_invoices(state, data) {
    state.invoices = data;
  },
  set_notes(state, data) {
    state.notes = data;
  },
  set_data(state, data) {
    state.invoicedata = data;
  },
  set_single_invoice(state, data) {
    state.singleInvoice = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
