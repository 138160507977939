import axios from "axios";
import { authHeaders } from "@/store/config";
// import EventBus from "@/common/EventBus";

const state = {
  biller: [],
  data: {},
  timekeeperCard: {},
  clientCard: {},
  matterCard: {},
  employeeCard: {},
  responsibleCard: {},
  billingCard: {},
  invoiceCard: {},
};

const getters = {};

const actions = {
  getBiller(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const type = (data && data.type) || "";
      const startDate = (data && data.startDate) || "";
      const endDate = (data && data.endDate) || "";
      const search = (data && data.search) || "";

      axios
        .get("/time-entry/biller/dashboard", {
          params: {
            year: year,
            type: type,
            startDate: startDate,
            endDate: endDate,
            search: search,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit(
              "set_biller",
              response?.data?.metrics || response?.data?.invoices
            );
            // context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBillerClient(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const id = (data && data.clientId) || "";
      // const startDate = (data && data.startDate) || "";
      // const endDate = (data && data.endDate) || "";

      axios
        .get("/time-entry/biller/dashboard/client", {
          params: {
            year: year,
            clientId: id,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_clientcard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBillerMatter(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const id = (data && data.clientId) || "";
      // const startDate = (data && data.startDate) || "";
      // const endDate = (data && data.endDate) || "";

      axios
        .get("/time-entry/biller/dashboard/matter", {
          params: {
            year: year,
            matterId: id,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_projectcard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBillerMatterEmployee(context, data) {
    return new Promise((resolve, reject) => {
      const id = (data && data.matterId) || "";
      const employeeId = (data && data.employeeId) || "";

      axios
        .get("/time-entry/biller/dashboard/matter/employee", {
          params: {
            matterId: id,
            employeeId: employeeId,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_employeecard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBillerBilling(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const id = (data && data.clientId) || "";
      // const startDate = (data && data.startDate) || "";
      // const endDate = (data && data.endDate) || "";

      axios
        .get("/time-entry/biller/dashboard/billing/employee", {
          params: {
            year: year,
            employeeId: id,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_billingcard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBillerResponsible(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const id = (data && data.clientId) || "";
      // const startDate = (data && data.startDate) || "";
      // const endDate = (data && data.endDate) || "";

      axios
        .get("/time-entry/biller/dashboard/responsible/employee", {
          params: {
            year: year,
            employeeId: id,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_responsiblecard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMatterCard(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const month = (data && data.month) || "";
      const id = (data && data.matterId) || "";

      axios
        .get("/time-entry/timekeeper/dashboard/project-metrics", {
          params: {
            month: month,
            matterId: id,
            year: year,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_projectcard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBillerInvoice(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const id = (data && data.clientId) || "";
      // const startDate = (data && data.startDate) || "";
      // const endDate = (data && data.endDate) || "";

      axios
        .get(`/time-entry/biller/dashboard/invoice/${id}`, {
          params: {
            year: year,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_invoicecard", response.data.data.invoice);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  set_biller(state, data) {
    state.biller = data;
  },
  set_data(state, data) {
    state.data = data;
  },
  set_timekeepercard(state, data) {
    state.timekeeperCard = data;
  },
  set_clientcard(state, data) {
    state.clientCard = data;
  },
  set_projectcard(state, data) {
    state.matterCard = data;
  },
  set_employeecard(state, data) {
    state.employeeCard = data;
  },
  set_billingcard(state, data) {
    state.billingCard = data;
  },
  set_responsiblecard(state, data) {
    state.responsibleCard = data;
  },
  set_invoicecard(state, data) {
    state.invoiceCard = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
