// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";

const state = {
  permissions: [],
  permissionsList: [],
  roledata: {},
};

const getters = {};

const actions = {
  createPermissions(data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/permission/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createRole(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/roles/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("set_role", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePermissionsValue(context, { id, permissions }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/permission/value/${id}`, permissions, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePermissions(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/permission/`, data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getPermissions(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/permission/all", {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_permissions", response.data.data);
            // context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPermissionsList(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/permission/list-permission", {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_permissionsList", response.data);
            // context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  set_permissions(state, data) {
    state.permissions = data;
  },
  set_permissionsList(state, data) {
    state.permissionsList = data;
  },
  set_types(state, data) {
    state.expensesTypes = data;
  },
  set_data(state, data) {
    state.expensesdata = data;
  },
  set_role(state, data) {
    state.roledata = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
