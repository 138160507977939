// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";
import EventBus from "@/common/EventBus";

const state = {
  expenses: [],
  expensesdata: {},
  expensesTypes: {},
};

const getters = {};

const actions = {
  createExpense(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/expenses/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("add_expense", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteExpense(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/expenses/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateExpense(context, { id, expense }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/expenses/${id}/`, expense, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          context.commit("update_expense", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getExpenses(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/expenses/all", {
          params: {
            search: data.search,
            pageNo: data.pageNo,
            pageSize: data.pageSize,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_expenses", response.data.data);
            context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getExpenseTypes(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/expenses-category/all", {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_types", response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  add_expense(state, data) {
    state.expenses.push(data);
  },
  set_expenses(state, data) {
    state.expenses = data;
  },
  set_types(state, data) {
    state.expensesTypes = data;
  },
  set_data(state, data) {
    state.expensesdata = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
